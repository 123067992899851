import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">

<path d="M2176 3694 c-200 -39 -374 -124 -529 -257 l-58 -50 52 -53 52 -53 71
58 c125 103 266 170 429 206 109 24 339 17 447 -14 114 -33 265 -111 355 -184
l80 -65 53 51 53 52 -43 38 c-160 142 -354 237 -556 272 -104 18 -312 18 -406
-1z"/>
<path d="M2275 3400 c-83 -13 -188 -46 -260 -82 -58 -29 -205 -134 -205 -146
0 -4 23 -30 52 -59 l51 -52 46 41 c62 55 130 92 226 125 70 24 95 28 200 28
99 0 132 -4 190 -24 89 -30 178 -78 236 -130 l46 -40 54 55 53 54 -34 30
c-179 154 -438 233 -655 200z"/>
<path d="M3091 3016 l-104 -105 49 -3 49 -3 3 -157 3 -158 104 0 105 0 0 159
0 160 50 3 51 3 -103 103 -103 102 -104 -104z"/>
<path d="M2263 3086 c-69 -17 -151 -58 -199 -100 l-39 -35 51 -52 52 -52 33
26 c81 60 117 72 224 72 107 0 143 -12 224 -72 l33 -26 52 52 51 52 -38 33
c-48 43 -147 91 -215 105 -68 14 -163 13 -229 -3z"/>
<path d="M2790 2665 l0 -155 -52 0 -53 0 105 -105 105 -105 103 102 103 103
-51 3 -50 3 0 155 0 154 -105 0 -105 0 0 -155z"/>
<path d="M2315 2751 c-73 -33 -105 -86 -105 -169 0 -56 23 -98 76 -138 32 -25
47 -29 100 -29 54 0 67 4 101 31 66 53 91 136 62 211 -22 59 -100 113 -164
113 -16 0 -48 -9 -70 -19z"/>
<path d="M1580 2047 c-19 -6 -53 -30 -76 -52 -67 -67 -80 -165 -34 -255 58
-113 219 -143 316 -58 42 37 61 72 70 127 l7 41 -107 0 -106 0 0 -30 c0 -30 1
-30 55 -30 59 0 71 -13 38 -42 -43 -38 -133 -39 -173 -3 -46 42 -50 141 -7
190 45 52 149 47 190 -10 15 -19 27 -25 58 -25 46 0 50 15 18 66 -43 71 -164
110 -249 81z"/>
<path d="M2423 2049 c-43 -13 -83 -62 -83 -104 0 -76 36 -108 148 -135 75 -17
93 -63 36 -89 -38 -17 -53 -14 -86 19 -23 23 -39 30 -69 30 -43 0 -45 -5 -25
-55 38 -90 192 -110 268 -35 23 23 28 37 28 77 0 77 -31 107 -135 132 -58 14
-75 26 -75 52 0 42 69 51 104 14 17 -18 34 -25 61 -25 37 0 37 1 31 32 -15 77
-105 115 -203 87z"/>
<path d="M1990 1845 l0 -205 40 0 39 0 3 78 3 77 73 3 73 3 -3 37 -3 37 -73 3
-73 3 3 42 3 42 78 3 77 3 0 39 0 40 -120 0 -120 0 0 -205z"/>
<path d="M2772 1891 c3 -182 9 -198 89 -239 77 -41 169 -17 219 56 23 33 25
46 29 189 l3 153 -46 0 -46 0 0 -139 c0 -133 -1 -141 -24 -167 -31 -37 -90
-39 -117 -5 -17 20 -19 41 -19 167 l0 144 -46 0 -45 0 3 -159z"/>
<path d="M3250 1845 l0 -205 99 0 c111 0 141 8 176 46 38 41 31 132 -12 157
-11 7 -10 13 7 34 28 36 27 103 -2 130 -41 37 -65 43 -167 43 l-101 0 0 -205z
m188 119 c48 -33 21 -74 -50 -74 l-48 0 0 45 0 45 38 0 c21 0 48 -7 60 -16z
m24 -179 c18 -39 -12 -65 -74 -65 l-48 0 0 45 0 45 55 0 c49 0 57 -3 67 -25z"/>
<path d="M1093 1370 c0 -30 2 -43 4 -27 2 15 2 39 0 55 -2 15 -4 2 -4 -28z"/>
<path d="M1222 1408 c-7 -7 -12 -17 -12 -22 0 -6 5 -3 11 7 5 9 21 17 34 17
13 0 29 -8 34 -17 6 -10 11 -13 11 -7 0 17 -22 34 -45 34 -12 0 -26 -5 -33
-12z"/>
<path d="M1610 1370 l0 -50 33 1 c30 2 30 2 5 6 -25 4 -28 8 -28 43 0 35 3 39
28 43 25 4 25 4 -5 6 l-33 1 0 -50z"/>
<path d="M1917 1402 c-22 -24 -21 -45 1 -65 23 -21 46 -22 66 -1 20 20 20 48
0 68 -21 21 -47 20 -67 -2z m63 -7 c26 -32 -13 -81 -47 -59 -35 22 -23 74 17
74 10 0 23 -7 30 -15z"/>
<path d="M2123 1413 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M2402 1408 c-7 -7 -12 -16 -12 -22 0 -5 6 -2 13 9 11 13 21 16 43 11
21 -5 25 -4 14 4 -20 13 -43 13 -58 -2z"/>
<path d="M2643 1370 c0 -30 2 -43 4 -27 2 15 2 39 0 55 -2 15 -4 2 -4 -28z"/>
<path d="M2773 1413 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M2950 1411 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M3101 1368 c1 -29 4 -43 6 -30 3 13 12 22 23 22 10 0 21 4 24 9 3 4
-6 6 -19 3 -20 -3 -25 0 -25 17 0 22 34 31 43 11 3 -6 4 -5 3 2 -1 7 -15 14
-29 16 -26 3 -27 2 -26 -50z"/>
<path d="M3390 1370 c0 -46 2 -50 24 -50 13 0 30 8 37 18 11 14 11 15 -1 5 -8
-7 -22 -13 -32 -13 -26 0 -26 76 0 83 13 4 11 5 -5 6 -21 1 -23 -3 -23 -49z"/>
<path d="M3596 1404 c-3 -9 -4 -19 -1 -22 3 -3 5 1 5 9 0 11 2 11 10 -1 5 -8
10 -10 10 -5 0 6 -4 16 -9 23 -6 11 -10 10 -15 -4z"/>
<path d="M3753 1413 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M3926 1403 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M1414 1387 c-3 -16 -4 -31 0 -34 3 -4 6 5 6 18 0 19 2 21 10 9 5 -8
10 -10 10 -5 0 6 -4 17 -9 24 -8 12 -11 9 -17 -12z"/>
<path d="M1476 1394 c-3 -9 -4 -19 -1 -22 3 -3 5 1 6 9 1 8 5 3 9 -11 7 -21 8
-21 9 -5 0 11 -3 26 -8 33 -6 11 -10 10 -15 -4z"/>
<path d="M2936 1374 c-8 -22 -9 -21 24 -26 32 -5 35 -1 20 22 -6 10 -10 11
-10 3 0 -7 -7 -13 -15 -13 -9 0 -12 6 -9 15 4 8 4 15 1 15 -3 0 -8 -7 -11 -16z"/>
<path d="M3906 1365 c-3 -9 -6 -18 -6 -19 0 -1 14 -1 30 2 17 2 30 8 30 13 0
5 -7 6 -16 2 -9 -3 -19 0 -24 6 -5 9 -9 8 -14 -4z"/>
<path d="M3575 1351 c-3 -8 -3 -16 0 -19 3 -3 5 0 5 6 0 18 41 14 57 -5 12
-16 12 -15 3 7 -12 28 -56 35 -65 11z"/>
<path d="M1210 1351 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
<path d="M1287 1346 c-3 -8 -16 -16 -29 -19 -21 -4 -21 -4 -2 -6 21 -1 52 24
42 35 -3 3 -8 -2 -11 -10z"/>
<path d="M1445 1343 c3 -8 5 -18 5 -21 0 -8 20 20 20 29 0 3 -7 6 -15 6 -10 0
-14 -6 -10 -14z"/>
<path d="M2390 1354 c0 -18 27 -36 47 -31 14 4 14 5 -4 6 -12 0 -27 9 -32 19
-6 9 -11 12 -11 6z"/>
<path d="M2773 1323 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
